var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Поурочное планирование","filter":"","create":"","create-title":"Создать запись","url-create":"/lesson-plan/create","url-update":"/lesson-plan/update/:id","store-module":"lesson_plan","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Год', value: 'year', width: 1, sortable: false },
        { text: 'Предмет', value: 'subject', width: 1 },
        { text: 'Класс', value: 'grade', width: 1 },
        { text: 'Номер урока', value: 'number', width: 1 },
        { text: 'Тема', value: 'theme', width: 1 },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems,"data-table-options":{
        'show-select': true,
    }},scopedSlots:_vm._u([{key:"filter",fn:function({ filter }){return [(_vm.loading.initial)?_c('v-progress-circular',{staticClass:"d-flex mx-auto",attrs:{"size":50,"color":_vm.$const.color.primary,"indeterminate":""}}):_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Календарный год","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.year),callback:function ($$v) {_vm.$set(filter, "year", $$v)},expression:"filter.year"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"required":"","items":_vm.subjects,"label":"Предмет"},model:{value:(filter.subject),callback:function ($$v) {_vm.$set(filter, "subject", $$v)},expression:"filter.subject"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"disabled":!filter.subject,"required":"","items":_vm.getGrades(filter.subject),"label":"Класс"},model:{value:(filter.grade),callback:function ($$v) {_vm.$set(filter, "grade", $$v)},expression:"filter.grade"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Номер урока","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.number),callback:function ($$v) {_vm.$set(filter, "number", $$v)},expression:"filter.number"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Тема","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.theme),callback:function ($$v) {_vm.$set(filter, "theme", $$v)},expression:"filter.theme"}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }